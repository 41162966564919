import './home.scss';
import { on_ready, is_elementor_edit } from 'js/utils';
import { swiper_mobile_init } from 'js/functions';


console.log('home.js');
 
on_ready(()=>{

    if( is_elementor_edit() ) return; 

    ff_hotspot();
    swiper_mobile_init();

    document.body.addEventListener('swiper_mobile_init',(e)=>{ 
        new e.Swiper_Mobile({
            container: '.service_home ',
            items: '.item',
            bullets: false,
            arrows: true,
        })

        new e.Swiper_Mobile({
            container: '.news_home',
            items: '.item',
            bullets: false,
            arrows: true,
        })
 
    })
    
    
});
  

function ff_hotspot(){ 
     
    let btn = document.querySelectorAll('.e-hotspot__button'); 
    let close = document.querySelectorAll('.map_popup_close');

    close.forEach(item => {
        item.addEventListener('click',(e)=>{ 
            e.preventDefault(); 
            document.querySelectorAll('.hotspot-popup').forEach(e => {
                e.classList.remove('active');
            });
        });
    });

    btn.forEach(item => {
        item.addEventListener('click',(e)=>{ 
            e.preventDefault();
            let s = e.target; 
            let parent = s.closest('a');  
            let target = parent.href.split('#')[1];
            let box = document.getElementById(`${target}`); 
            box.classList.add('active');
        });
    });
     
}
 